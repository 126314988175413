import request from '@/utils/request'
// 美团团购列表
export function getTableList(data) {
  return request('post', '/apm/dianping/shop/list', data)
}

// 添加美团商户授权
export function oauthToken(data) {
  return request('post', '/apm/dianping/oauth/token', data)
}



// 绑定门店
export function getTableEdit(data) {
  return request('post', '/apm/dianping/shop/bind', data)
}